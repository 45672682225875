@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';

.card {
  border-radius: 0;
}

.card-img-container {
  display: flex;
  flex-direction: column;
}

.lens-image-container {
  width: 50%;
  display: flex;
  align-self: center;
  position: relative;
  top: 40px;
  z-index: 5;

  img {
    width: 100%;
    height: 100%;
  }
}

.card-lens-master {
  width: 85%;
  background-color: transparent;
  border: 2px solid #fff;
  margin-left: 5%;
}

.card-lens-container {
  background-color: #000;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem 0 1.5rem;

  h2 {
    font-family: Poppins;
    font-weight: bold;
    font-size: 25px;
    color: #70e6f0;
    margin: 5px 0 15px 0;
  }

  .info-carbon-footprint-mobile {
    border: 1px solid white;
    display: inline-block;
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius: 50%;
    margin-left: 2%;
    margin-top: 1%;
    color: white !important;
  }

  ul {
    border: 2px solid #fff;
    font-family: Poppins;
    list-style-type: none;
    padding: 0;
    color: #fff;
    margin-bottom: 3%;

    :first-child {
      border-top: none;
    }

    li {
      padding: 0 2%;
    }

    .list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 3% 0;
      border-top: 2px solid #fff;
      font-size: 17px;

      span {
        color: #70e6f0;
        font-size: 22px;

      }

      p {
        font-size: 17px;
        margin-bottom: 0;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
        line-height: normal;
      }
    }

    .list-title {
      font-size: 20px;
      font-weight: bold;
    }

    h5 {
      color: #fff;
      font-size: 17px;
      margin-bottom: 0;
      font-family: Poppins;
      font-weight: bold;
    }

  }

  .list-item:last-child {
    flex-direction: column;

    h5 {
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: normal;
      line-height: normal;
      align-self: flex-start;
    }

    p {
      text-align: start;
    }
  }

  .card-with-image-line {
    display: flex;
    align-self: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #70e6f0;
    cursor: pointer;
    text-transform: uppercase;
    margin-bottom: 3%;
  }
}

.mobile-info-footprint-modal {
  z-index: 90003;
  color: #fff;

  .modal-content {
    background-color: #000000;
    height: fit-content;
    width: max-content;
  }

  .modal-header {
    background-color: #000000;

    h5 {
      color: #fff;
      font-size: 1.25rem;
      margin: 0;
      font-weight: 400;
      font-family: TT Norms;
    }
  }

  button {
    color: #ffffff;
    filter: invert(1);
    -webkit-filter: invert(1);
  }


}

@media (max-height: 568px) and (max-width: 360px) {
  .card-img-container {
    width: 100%;
  }

  .card-lens-container {
    h2 {
      font-size: 20px;
      margin: 0 0 5px 0;
    }

    ul {
      .list-title {
        font-size: 15px;
      }

      h5 {
        font-size: 12px;
      }

      .list-item {
        span {
          font-size: 15px;
        }

        p {
          font-size: 12px;
        }
      }
    }
  }

  .card-with-image-line {
    font-size: 11px !important;
    line-height: 14px !important;
  }
}

@media only screen and (min-height: 850px) and (min-width: 541px) and (orientation: portrait) {
  .lens-image-container {
    width: 35%;
    top: 50px;
  }
}

@media (max-height: $mobile-height-max) and (max-width: $mobile-width-max) {
  .card-lens-container ul .list-item p {
    text-align: end;
  }
}