@import '../../../assets/styles/variables.scss';

.explanation-container {
    position: relative;
    left: 45px;
    bottom: 85px;
    display: flex;

    h6 {
        text-align: center;
        letter-spacing: 0px;
        color: #70E6F0;
        opacity: 1;
        width: 150px;
        height: 47px;
        position: relative;
        bottom: 10px;

    }
}

@media (max-height: $mobile-height-max) and (max-width: $mobile-width-max) and (min-width: 360px) {
    .explanation-container {
        bottom: 0;
        left: 90px;
        top: 20px;
        display: flex;
    }
}