@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';
@import 'node_modules/react-modal-video/scss/modal-video.scss';

.home-container {
  overflow: auto;
  z-index: 1;

  @media (min-width: $mobile-width-max) {
    width: 100%;
    overflow: initial;

    .video-background-container-dkt {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;

      video {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .karun-footer {
      margin-bottom: 1em;
    }
  }

  .home-search {
    height: calc(108% - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 19px;
    height: 100vh;

    .join-planet-dkt {
      width: 100%;
      margin-bottom: 4rem;
    }

    @media (max-width: 970px) and (max-height: 440px) and (orientation: landscape) {
      .join-planet-dkt {
        margin-bottom: 13rem;
      }
    }

    h1 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      line-height: 33px;
      text-align: center;

      color: #ffffff;
    }

    .input-text-container input {
      border: none;
      border-radius: 0;
      border-top: 2px solid #ffffff;
      border-bottom: 2px solid #ffffff;
      border-left: 2px solid #ffffff;
      background-color: #000000;
      color: #ffffff;
      width: 100%;
      max-width: 474px;
    }

    .input-text-container.input-search {
      margin-top: 0;
      display: flex;
      justify-content: center;
    }

    .input-text-container.input-search.error input {
      border: 2px solid #fb2424;
      color: #fb2424;
    }

    .input-text-container.input-search.error label {
      color: #fb2424;
    }

    .input-search.error input {
      background-image: url('../../../assets/images/error.png');
      background-position: 96% 14px;
      background-repeat: no-repeat;
    }

    .input-button-container {
      display: flex;
      width: 544px;
      height: 53px;

      .button-text-search {
        background-image: url('../../../assets/images/lens.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: inherit;
        background-color: #000000;
        border: none;
        border-top: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        border-right: 2px solid #ffffff;
        width: 70px;
        height: 53px;
        padding: 10px 30px 10px 6px;
      }

      .button-text-search.error {
        border-top: 2px solid #fb2424;
        border-bottom: 2px solid #fb2424;
        border-right: 2px solid #fb2424;
      }
    }
  }

  .home-instructions {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98%;
  }

  .home-steps {
    text-align: center;
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: black;
    width: 98%;
    max-width: 1100px;
    height: 519px;
    border: 1px solid white;

    @media (min-width: $mobile-width-max) and (orientation: landscape) {
      display: flex;

      div {
        margin: 5%;
      }
    }

    img {
      margin: 8px;
      height: 190px;
      width: 190px;
      border: 1px solid white;
      margin-bottom: 23px;
    }

    h3 {
      font-family: Poppins;
      font-style: normal;
      font-size: 30px;
      line-height: 21px;
      color: #70e6f0;
      margin-top: 16px;
      margin-bottom: 16px;
      text-align: center;
      text-transform: uppercase;
    }

    p {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 22px;
      color: #ffffff;
      text-align: center;
      height: 130px;
      width: 100%;
      margin: 0 auto;
    }

    button {
      margin-top: 53px;
    }
  }

  .home-instruction-step {
    margin: 0.5em !important;
    width: 350px;
  }

  .overlay-background {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 98%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .home-instructions-closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
    z-index: 1000;
    color: #fff;
    margin-top: 0px !important;
  }

  .karun-footer {
    position: relative;
    top: 10%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 60px;
    margin-bottom: 1rem;
  }

  .home-go-down {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    margin-bottom: 35px;
    color: #fff;
  }

  .home-go-down svg {
    animation-name: mymove;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 1s;
  }

  .home-demo {
    padding: 20px 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      text-align: center;
      font-family: TT Norms;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      color: #444444;
    }

    iframe {
      margin: 40px 0;
    }

    a {
      background-color: #000;
      color: #fff;
      font-family: TT Norms;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 33px;
      padding: 5px 40px;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        color: #fff;
        background-color: #444;
      }
    }
  }
}

.home-subtitle {
  font-family: Poppins;
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 23px;
}

.home-title {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 35px;
  line-height: normal;
  max-width: 900px;
}

.no-code-look {
  font-family: Poppins;
  cursor: pointer;
  font-weight: normal;
  text-decoration: none;
  color: white;
  margin-bottom: 37px;
  margin-top: 30px;

  a {
    margin-left: 1px !important;
  }
}

.no-code-look:last-child {
  content: '';
  color: #57e7ef;
  margin-left: 5px !important;
}

.how-to-enter-code {
  background-color: #57e7ef;
  padding: 13px 28px;
  text-transform: uppercase;
  width: fit-content;
  border: 1px solid black;
  font-weight: bold;
  color: black;
  margin: 1.25rem auto 0;
}

.how-to-enter-code:hover {
  cursor: pointer;
}

.home-steps button {
  width: 33px;
  height: 33px;
  font-size: 25px;
  font-family: Poppins;
  font-weight: bold;
}

.input-and-qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .input-qr-inner-container {
    display: flex;
    width: 100%;
  }
}

.input-error p {
  color: red;
  font-weight: bold;
  text-align: center;
}

div.form-container label.form-label {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
}

@media (min-width: $mobile-width-max) and (orientation: landscape) {
  .overlay-background {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-steps {
    position: inherit;
  }
}

.hide {
  display: none;
}

@media (max-height: $mobile-height-max) and (max-width: $mobile-width-max) and (min-width: 360px) {
  .main-layout-container {
    overflow: visible;
    height: 100vh !important;
  }

  .input-error p {
    width: 300px;
  }

  .home-container {
    z-index: 999;
    height: 100vh;
    overflow: hidden;

    .home-steps {
      h3 {
        text-align: left;
      }

      p {
        text-align: left;
      }
    }

    .home-search {
      background: none;
      padding-top: 20%;

      .join-planet-mobile {
        margin-bottom: 2.5rem;
      }

      .input-text-container input {
        width: 100%;
      }

      .home-subtitle {
        text-align: center;
        font-size: 18px;
        margin-bottom: 0;
        width: 80%;
      }

      .home-title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
      }

      .form-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .form-label {
          align-self: center;
          color: #fff;
          margin-bottom: 0;
          font-size: 14px;
          margin-top: 3%;
        }

        .input-and-qr-container {
          flex-direction: column;
          align-items: center;

          .input-qr-inner-container {
            align-items: center;
            flex-direction: column;
          }
        }


        .input-button-container {
          display: flex;
          justify-content: center;
          margin-bottom: 5%;
          width: 100% !important;
        }

        .action-button {
          width: 60px;
          height: 62px;
          border: none;
          margin: 3% 0 5% 0;

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .qr-button-container {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
      }

      .input-text-container.input-search {
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
      }

      .home-go-down {
        display: flex;
        flex-direction: column;
        align-items: center;

        .no-code-look {
          font-size: 15px;
          margin-bottom: 3%;
          margin-top: 0;
        }

        .how-to-enter-code {
          font-size: 14px;
          margin-top: 0;
        }
      }
    }

    .overlay-background {
      z-index: 9999;

      .home-steps {
        height: 95vh;
        top: 0;
        display: block;
        overflow: auto;
        margin: 5%;
        position: unset;

        .home-instructions-closeButton {
          font-size: 21px;
          top: 25px;
          right: 25px;
        }

        .home-instruction-step {
          width: 100%;
          padding: 5% 5% 0 5%;
          margin: 0 !important;

          h3 {
            font-size: 18px;
            margin-top: 0;
          }

          img {
            width: 100%;
            height: auto;
            margin: 0 0 5% 0;
          }

          p {
            font-size: 17px;
            margin-bottom: 0;
          }
        }

        .footer-logo {
          width: 45%;
        }
      }

      @media (min-width: 361px) and (max-width: 540px) and (max-height: 720px) {
        .home-container {
          .home-search {
            padding-top: 0;
          }
        }
      }

      .karun-footer {
        padding-top: 2rem;
        margin-bottom: 0 !important;
      }

      .footer-logo {
        width: 45%;
      }
    }

    @media (max-height: 570px) {
      .home-search {
        .join-planet-mobile {
          margin-bottom: 1.5rem !important;
        }
      }

      .karun-footer {
        margin-bottom: 0 !important;
      }
    }

    @media (max-width: 360px) {
      .home-container {
        @media (max-height: 570px) {
          .home-search {
            padding-top: 15%;

            .home-subtitle {
              width: 100%;
              font-size: 14px;
            }

            .home-title {
              font-size: 14px;
            }

            .input-button-container {
              height: 40px;

              .button-text-search {
                height: 40px;
              }
            }

            .action-button {
              width: 50px !important;
              height: 52px !important;
            }

            .home-go-down {
              .no-code-look {
                font-size: 13px;
                margin-bottom: 8%;
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
}