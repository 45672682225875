@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';

@media (min-width: 768px) and (orientation: landscape) {
  .action-button-homelens {
    margin-top: 30px !important;
  }
}

.lens-container {
  overflow: initial;
  position: relative;

  height: 100vh;
  // background: url('../../../assets/images/background-country-selection2.png');
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;

  @media (min-width: $mobile-width-max) {
    width: 100%;
    overflow: initial;

    .video-background-container-dkt {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;

      video {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .karun-footer {
      margin-bottom: 1em;
    }
  }

  @media (min-width: $mobile-width-max) and (orientation: landscape) {
    width: 100%;
  }

  .close-icon {
    @media (min-width: $mobile-width-max) and (orientation: landscape) {
      display: block;
      position: absolute;
      top: -47px;
      right: 2%;
    }

    position: absolute;
    right: 24px;
    top: 24px;
  }

  .lens-content {
    min-height: 100vh;
    margin: 16px;
    margin-top: 50px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @media (min-width: $mobile-width-max) and (orientation: landscape) {
      min-height: 79vh;
      margin: auto;
      max-width: 780px;
      justify-content: flex-end;
    }

    .lens-data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      .select h6 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 35px;
        line-height: 19px;
        color: #ffffff;
        text-align: center;
        margin-bottom: 20px;
        text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.3);
      }

      .select select {
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 19px;
        color: #ffffff;
        background: url('../../../assets/images/arrow-white.png');
        background-repeat: no-repeat;
        background-color: black;
        background-position: right 0.75rem center;
      }

      .select .form-select .select-option {
        font-family: 'Calluna Sans';
        font-style: normal;

        font-weight: 700;
        font-size: 16px;
        line-height: 19px;

        color: #2b2b2b;
      }

      .select-all-fields {
        color: #ffffff;
        font-family: 'Calluna Sans';
        font-style: normal;
        margin-bottom: 0;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
      }

      .input-error {
        text-align: center;
      }
    }

    .last-element {
      margin-bottom: 50px;
    }

    @media (max-width: 970px) and (max-height: 440px) and (orientation: landscape) {
      .last-element {
        margin-top: 120px;
      }
    }


    @media (min-height: 500px) and (max-height: 630px) and (orientation: landscape) {
      .last-element {
        margin-top: 70px;
      }
    }

    .lens-title {
      font-family: Calluna Sans;
      font-size: 1.5em;
      font-style: normal;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #444444;
    }

    .lens-description {
      font-family: Calluna Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #4d8275;
    }

    .joinPlanetDkt {
      position: absolute;

      top: 12%;
    }

    .lens-image {
      width: 100%;
      height: 250px;
      object-fit: contain;
    }

    .lens-image.SWFS0015 {
      width: 100% !important;
    }
  }

  @media (min-width: 768px) {
    .lens-content {
      min-height: unset;
      height: 100vh;
      display: flex;
      justify-content: center;
    }

    .lens-content {
      display: flex;
      justify-content: flex-start;
    }

    .lens-image {
      height: 15em;
      margin-top: 6em;
    }

    .lens-data:first-child {
      height: calc(100% - 8rem);
    }

    .lens-data .selects-container .select {
      height: 70px;
    }

    .lens-data .selects-container .select h6 {
      // text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.3);
      height: 20px;
    }

    .select-label {
      margin-bottom: 0;
    }

    .select-all-fields {
      font-size: 16px;
      margin-top: 25px;
      // text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
    }

    .action-button {
      margin-bottom: 20%;
      // margin-top: 30px !important;
    }
  }

  .lens-go-down {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    margin-bottom: 35px;
  }

  .lens-go-down img {
    animation-name: mymove;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 1s;
  }
}

.header-search-desktop {
  .language-switch .form-select {
    background-color: transparent;
    border: none transparent;
    color: #2b2b2b;
    font-family: Poppins;
    background: url('../../../assets/images/arrow-black.png');
    background-repeat: no-repeat;
    background-position: right 0.75rem center;

    option {
      background: transparent;
      color: #2b2b2b;
    }
  }
}

.back-button {
  position: fixed;
  left: 60px;
  bottom: 68px;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ffffff;

    div {
      background: url('../../../assets/images/timeline-arrow.png') no-repeat;
      background-size: contain;
      transform: scaleX(-1);
      width: 10px;
      height: 19px;
      -webkit-transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      -ms-transform: scaleX(-1);
      -o-transform: scaleX(-1);
    }
  }

  p {
    margin: 0;
    margin-left: 11px;
    margin-bottom: 4px;
    text-decoration: none;
  }
}

.modal-header {
  background-color: #000000;
}

@keyframes mymove {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(20px);
  }
}

.action-button-homelens {
  margin-top: 51px !important;
  margin-left: 0px;
  width: 183px;
  height: 33px;
  background-color: #70e6f0;
  color: #000000;
  font-size: 20px;
  text-transform: uppercase;
  font-family: Poppins;
}

@media (max-height: 1300px) and (max-width: $mobile-width-max) {
  .main-layout-container {
    background-color: #0a0d14;
  }

  // .lens-container {
  //   overflow: hidden;
  //   background-position: center -25px;
  // }

  .lens-content {
    margin: 8% !important;
    justify-content: flex-end !important;

    .lens-data {
      .joinPlanetMobile {
        position: absolute;
        top: 25%;

        path {
          fill: #000000;
        }
      }

      .lens-image {
        position: absolute;
        width: 75%;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .selects-container {
        margin-bottom: 10%;
        align-items: flex-end;
        min-height: 180px;

        .form-select {
          padding-top: 0;
          padding-bottom: 0;
          height: 35px;
        }
      }

      .select select {
        font-size: 1.2em !important;
      }

      .select-label {
        margin-bottom: 5% !important;
      }

      .action-button-homelens {
        margin-top: 0 !important;
        margin-bottom: 25%;
        margin-top: 10%;
        width: 70%;
        border: none;
      }

      .back-button {
        display: none;
      }
    }
  }
}

@media (max-height: 568px) and (max-width: 360px) {
  .lens-content {
    .lens-image {
      height: min-content !important;
      padding-bottom: 35px;
    }

    .lens-data {
      .joinPlanetMobile {
        top: 25%;
      }

      .selects-container {
        margin-bottom: 2%;
        min-height: 150px;
      }

      .select-label {
        margin-bottom: 2% !important;
        font-size: 20px !important;
      }

      .select select {
        font-size: 1em !important;
        margin-bottom: 2%;
      }

      .action-button-homelens {
        font-size: 18px;
        height: 30px;
      }
    }
  }
}

@media (max-height: 720px) and (max-width: 540px) and (min-width: 361px) {
  .lens-container {
    background-position: center -10px;
  }

  .lens-content {
    .lens-image {
      padding-bottom: 35px;
    }

    .lens-data {
      .selects-container {
        min-height: 150px;
        margin-bottom: 2%;
      }

      .select-label {
        margin-bottom: 3% !important;
        font-size: 20px !important;
      }

      .select select {
        font-size: 1em !important;
        margin-bottom: 2%;
      }

      .action-button-homelens {
        font-size: 18px;
        height: 30px;
        margin-bottom: 20% !important;
      }
    }
  }
}