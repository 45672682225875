@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';

@media (min-width: 768px) {
  .card-lens-master-desktop {
    display: flex;
    flex-direction: row;
    width: calc(100% - 350px);
    min-height: 250px;
    margin-top: 23px;
    border: none !important;
    margin-bottom: 4em;
    background-color: #000000;
    color: #ffffff;

    .card-with-image-line {
      display: flex;
      align-self: center;
      font-family: TT Norms;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      color: $grey;
      margin-bottom: 0.5rem;
      -moz-text-decoration-line: underline;
    }
  }

  .card-content {
    display: flex;
    width: 100%;
    max-width: 1400px;

    #card-info {
      width: 100%;
    }
  }

  .card-lens-trace {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    width: 95%;

    ul {
      width: 95%;
    }

    p {
      font-family: Calluna Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
    }

    h4 {
      font-size: 16px;
      font-weight: bold;
      margin-top: 1.5em;
      margin-bottom: -10px;
    }

    ul li {
      font-family: Poppins;
      font-size: 17px;
      margin-bottom: 10px;
      line-height: 22px;
    }

    ul li::marker {
      color: #ee344a;
    }
  }

  .card-lens-container-desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 20px;
    padding: 0 1rem;
    margin-bottom: 1em;

    h2 {
      font-family: TT Norms;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #9cb9ad;
      margin: 5px 7px 5px 0px;
    }

    h3 {
      font-family: Calluna Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
      margin-bottom: 18px;
    }

    .card-lens-desktop {
      display: flex;
      flex-direction: row;
      width: 100%;

      h2 {
        text-align: right;
        font-size: 16px;
      }

      h3 {
        font-family: Poppins;
        font-size: 25px;
        color: #70e6f0;
      }
    }
  }

  .card-lens-image-desktop {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 250px;

    .lens-image-container-desktop {
      display: flex;
      width: 230px;
      height: fit-content;
      align-self: center;
      filter: drop-shadow(2px 3px 5px white);
      -webkit-filter: drop-shadow(2px 3px 5px white);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .detail-box {
    flex: 1 1;
    padding: 7px 12px;
    border-radius: 10px;

    margin-right: 10px;
    width: 200px;

    h3 {
      font-family: TT Norms;
      font-style: normal;
      font-weight: bold;
      color: #70e6f0;
      font-size: 20px;

      margin-bottom: 0px;
    }
  }

  .co2-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 330px;
    margin-right: 50px;
  }

  .carbon-data-container {
    display: flex;
    width: 130px;

    h5 {
      display: flex;
      margin: 0;
      align-items: center;
    }
  }

  #co2-info,
  #co2-info2,
  #co2-info3 {
    display: flex;
    border-bottom: 1px solid #ffffff;
  }

  .materiales-cardzero {
    color: #70e6f0;
  }

  .huella-carbon-peso {
    font-size: 18px;
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    width: auto;
    margin: 4px 2px 8px 20px;
  }

  .cardzero-noscroll {
    overflow: hidden;
    background-color: #000000 !important;
  }

  @media (max-width: 980px) {
    .card-lens-container-desktop {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: fit-content;
      padding: 0;
      margin-bottom: 0;
    }

    .card-lens-master-desktop {
      display: flex;
      flex-direction: row;
      width: 100%;
      min-height: 220px;
      margin-top: 23px;
      border: none !important;
      margin-bottom: 2em;
    }

    .card-lens-container-desktop .card-lens-desktop h3 {
      margin-bottom: 0px;
    }

    .cardzero-noscroll {
      overflow-y: scroll !important;
    }

    #co2-container {
      position: relative;
      margin: 0 auto;
      margin-top: -20px;
    }

    #co2-info,
    #co2-info3 {
      flex-direction: row !important;
    }
  }

  //end max-width 980px

  .audit-log-closebtn {
    border: none;
    position: absolute;
    right: 0;
    z-index: 1;
  }

  .audit-log-closebtn button {
    filter: invert(1) !important;
    -webkit-filter: invert(1) !important;
  }

  #blockchain-data {
    font-size: 18px !important;
    border-left: 1px solid white;
    padding-left: 22px;
    margin-left: 20px;

    p {
      margin: 0;
      font-size: 18px;

      img {
        width: 22px;
        margin-left: 10px;
        filter: invert(1);
      }
    }
  }

  .audit-log-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
  }

  .audit-log-modal-dkt {
    z-index: 90002;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .modal-dialog {
      width: 700px;

      .modal-content {
        overflow: auto;
        background-color: #000000;
        color: #ffffff;

        .modal-body {
          background-color: #000000;
          font-family: Poppins;

          h5.audit-log-title {
            color: #ffffff;
            font-weight: bold;
            font-family: Poppins;
            padding-bottom: 10px;
            border-bottom: 2px solid #70e6f0;
            margin-bottom: 25px;
          }

          .audit-log-sku-title {
            color: #ffffff;
            margin-right: 10px;
            font-weight: normal;
            font-size: 20px;
          }

          .audit-log-sku-batch {
            color: #70e6f0;
            font-weight: bold;
            font-size: 20px;
          }

          .accordion-button {
            background-color: #000000;
            color: #fff;
            font-weight: 500;
            font-family: Poppins;
            border: 2px solid white;
          }

          .accordion-button::after {
            filter: invert(1) grayscale(100%) brightness(200%);
          }

          .accordion-body {
            background-color: #000000;
            color: #fff;
            font-family: Poppins;

            h6 {
              font-weight: 700;
              color: #70e6f0;
            }
          }
        }
      }
    }
  }
}