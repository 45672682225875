.ĺast-card-container {
    display: flex;
    flex-direction: column;

    .card-with-image {
        max-width: 85%;
        width: 100%;
        background-color: #000;
        border: 2px solid white;
        margin-top: 0 !important;

        .card-body {
            display: flex;
            align-items: center;
            text-align: center;
            padding-left: 20px;
            font-family: 'Calluna Sans';
            font-size: 16px;
            min-height: 300px;


            .card-title-container {
                display: flex;
                text-align: left;
                align-items: center;
                min-height: 44px;
                font-family: Poppins;
                margin-bottom: 2%;

                span {
                    background-color: #70e6f0;
                    color: #000;
                    font-weight: bold;
                    padding: 4px 12px;
                    border-radius: 100%;
                    margin-right: 3%;
                    font-size: 18px;
                    font-weight: bold;
                    height: min-content;
                    width: min-content;
                }

                h2 {
                    color: #fff;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    white-space: normal;
                    font-size: 18px;
                    font-weight: bold;
                    margin: 0;
                }

                a {
                    color: #70e6f0;
                    text-transform: uppercase;

                }
            }

        }
    }

    .card-iconic-image {
        width: 50%;
        display: flex;
        align-self: center;
        position: relative;
        top: 55px;
        z-index: 5;

        .iconic-image-container {
            display: flex;
            position: relative;
            top: -5px;
            width: 100%;
            min-width: 195px;
            min-height: 98px;
            align-self: center;
            margin-bottom: 1rem;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}