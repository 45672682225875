@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';

.rec.rec-carousel-wrapper {
  margin-left: 0%;

  @media (min-width: $mobile-width-max) and (orientation: landscape) {
    margin-left: 0%;

    .rec.rec-arrow {
      background: none;
      border: none;
      box-shadow: none;
      color: #ffffff;
      display: flex;
      justify-content: center;
    }

    .rec.rec-arrow:hover {
      color: #70e6f0;
    }
  }
}

.rec.rec-slider-container {
  overflow: hidden;

  @media (min-width: $mobile-width-max) and (orientation: landscape) {
    overflow: hidden;
  }
}

.card-pill {
  background: #fff;
  border-radius: 25.5px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  padding: 4px 14px 4px 0px;
  width: 100%;
}

.card-pill.card-pill-material {
  width: 360px;
  font-family: TT Norms;
  font-size: 17px;
  color: #70e6f0;
  background: #000000;
  margin-bottom: 5px;
}

#offcanvasDetail h5 {
  font-family: Poppins;
  font-weight: bold;
  font-size: 18px;
  color: #70e6f0;
  margin: 5% 0;
}

#offcanvasDetail h2 {
  margin-top: 26px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}

#offcanvasDetail p {
  margin-top: 16px;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: break-spaces;
  margin-bottom: 16px;
}

.detail-image {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 192px;
  color: #fff;
  font-size: 4em;
}

button.btn-close {
  float: right;
}

.detail-recolections {
  display: flex;
  margin-bottom: 31px;

  .detail-box {
    flex: 1;
    padding: 14px 16px;
    border-radius: 10px;

    h3 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 43px;
      letter-spacing: 0.02em;

      color: #ffffff;
    }

    h4 {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 14px;
      letter-spacing: 0.02em;

      color: #ffffff;

      opacity: 0.8;
    }
  }

  .detail-box-kg {
    background: #70e6f0;
    margin-right: 10px;
  }

  .detail-box-col {
    background: #9fb9ac;
  }
}

.offcanvas-backdrop {
  background-color: $grey;
  opacity: 0.9 !important;
}

#offcanvasDetail.offcanvas-bottom {
  max-height: 100%;
  height: 85vh;
  background: #000;
  padding: 18px;
  overflow-x: hidden;
  margin: 12% 5% 5% !important;
  border: 2px solid white;
}

#offcanvasDetail .offcanvas-body {
  overflow-x: hidden;
  margin-top: 5%;
  padding: 0 5% 5% 5%;
}

.btn-close.btn-close-white {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 21px;
}

@media (min-width: 768px) {
  .desktop-recolections div.detail-box.detail-box-kg {
    display: flex;
    align-items: center;
    height: 50px;
    max-width: fit-content;
    background: none;
    margin-right: 10px;

    h4 {
      margin-bottom: 0px;
      white-space: nowrap;
      font-size: 13px;
    }
  }

  .desktop-recolections div.detail-box.detail-box-col {
    display: flex;
    align-items: center;
    height: 50px;
    max-width: fit-content;
    background: none;
    margin-right: 10px;
    width: 100%;

    h4 {
      margin-bottom: 0px;
      white-space: nowrap;
      font-size: 13px;
    }
  }
}