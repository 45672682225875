@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';

.offcanvas {
  margin: 5% 5% 5% 5%;
  width: 90% !important;
  height: fit-content;
}

.offcanvas-body {
  padding: 10%;
}

.lens-detail {
  background-color: #000;
  min-height: 100%;
  border: 2px solid white;

  .items-lens {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: -30px;

    .item-description {
      height: 75%;
      margin-top: -30px;

      .detail-box {
        margin-bottom: 16px;
      }

      .lens-trace-description {
        height: 100%;
      }
    }

    .item-shoes-image {
      display: flex;
      align-content: center;
      justify-content: center;
      width: 100%;

      img {
        width: 100%;
      }
    }

    ul {
      margin: 5% 0;
      list-style: none;

      li {
        font-family: TT Norms;
        font-size: 16px;
        color: #70e6f0;
        font-weight: 500;
        margin-bottom: 2%;

        span {
          color: #fff;
          font-weight: 400;
        }
      }

      li::before {
        content: '\2022';
        font-weight: bold;
        font-size: 2.5rem;
        display: inline-block;
        line-height: 0;
        color: #ee344a;
        width: 1em;
        margin-left: -1em;
        vertical-align: middle;
      }
    }

    hr {
      color: #70e6f0;
      height: 2px;
      opacity: 1;
    }
  }
}

.traceability {
  color: #fff;
  font-size: 14px;
}

.aws-check {
  color: #fff;
  justify-content: flex-start !important;
  margin-top: 0.5rem !important;
  //font-size: 14px!important;
}

.detail-audit-link {
  display: flex;
  justify-content: flex-start;
  margin-top: 12px;
  font-family: TT Norms;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #70e6f0;
  cursor: pointer;
}

.custom-modal-header {
  background: none;
  border: none;
  padding: 0;
  margin: 0;

  .btn-close {
    position: relative;
    right: 6px;
    top: 8px;
  }
}

.modal-body-auditlog {
  background-color: #000000;
  font-family: Poppins;

  h5 {
    color: #70e6f0;
    font-weight: bold;
    font-family: Poppins;
    font-size: 1.125rem;
  }

  .accordion-button {
    background-color: #000000;
    color: #fff;
    font-weight: 500;
    font-family: Poppins;
    border: 2px solid white;
  }

  .accordion-button::after {
    filter: invert(1) grayscale(100%) brightness(200%);
  }

  .accordion-body {
    background-color: #000000;
    color: #fff;
    font-family: Poppins;

    h6 {
      font-weight: 700;
      color: #70e6f0;
    }
  }
}

@media (max-height: 568px) and (max-width: 360px) {
  .lens-detail {
    .offcanvas-body {
      padding: 5% 10% 5%;
    }

    .items-lens {
      ul {
        margin: 0;

        li {
          font-size: 15px;
        }

        li::before {
          font-size: 2rem;
        }
      }
    }
  }

  .aws-check {
    font-size: 13px !important;
  }

  .detail-audit-link {
    font-size: 13px;
  }
}

@media (max-height: 720px) and (max-width: 540px) and (min-width: 361px) {
  .lens-detail {

    .detail-audit-link {
      margin-top: 8px;
    }

    .offcanvas-body {
      padding: 5% 5% 5%;
    }

    .items-lens {
      margin-top: -2px;

      ul {
        margin-top: 5%;
        margin-bottom: -2px;
      }
    }

    .items-lens .item-shoes-image img {
      width: 75%
    }
  }



  .video-container {
    width: 100% !important;
  }
}